import { WeekDay } from '@angular/common';
import { RoleModules, RolePermissions } from '../services/roles/role.types';

export enum RoleType {
  MASTER = 'MASTER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  CUSTOM = 'CUSTOM',
  COMPLEMENT_ADMINISTRATOR = 'COMPLEMENT_ADMINISTRATOR',
  BASIC_USER = 'BASIC_USER',
}

export interface Role {
  id: string;
  name: string;
  description: string;
  enabled: boolean;
  hasAllAccountsAllowed: boolean;
  users: string[];
  depositAccounts: string[];
  roleType: RoleType;
  workhours?: RoleWorkHours;
  permissions?: RolePermission[];
}

export interface RoleCreate {
  id?: string;
  name: string;
  description: string;
  enabled: boolean;
  hasAllAccountsAllowed: boolean;
  depositAccounts: string[];
  permissions: RolePermission[];
  users: string[];
  edited?: boolean;
  roleType: RoleType;
  workHours: RoleWorkHours;
  complements: string[];
}

export interface RoleWorkHours {
  allowedWeekDays: WeekDay[];
  initialTime: string;
  endTime: string;
}

export interface RoleFilter {
  users?: string[];
  depositAccounts?: string[];
  enabled?: boolean;
}

export interface RoleModulePermission {
  module: RoleModules;
  permissions: string[];
}

export interface RolePermission {
  module: RoleModules;
  permission: RolePermissions;
  options?: PermissionOptions;
}

export interface PermissionModuleModel extends RoleModel {
  isHidden: boolean;
  permissions: PermissionModel[];
}

export interface PermissionModel extends RoleModel {
  options?: PermissionOptions;
  isHidden?: boolean;
  module: string;
}

export interface PermissionOptions {
  needsAuthorization: boolean;
  type: any;
  amount: number;
  authorizers: string[];
  maximumAmount?:string;
  numberOfRoles?: { value: number }[];
  approvalsNeeded?: number;
}

export interface RoleModel {
  name: string;
  isChecked: boolean;
  isAvailable: boolean;
}

export interface RoleAuthorizers {
  TRANSACTIONS_OWN_ACCOUNTS: string[];
  TRANSACTIONS_ACCOUNTS_SAME_BANK: string[];
  TRANSACTIONS_ACCOUNTS_OTHER_BANK: string[];
  TRANSACTIONS_BATCHES: string[];
  PAYMENTS_PAYROLL_MANUAL: string[];
  PAYMENTS_PROVIDERS_MANUAL: string[];
  PAYMENTS_PAYROLL_BATCHES: string[];
  PAYMENTS_PROVIDERS_BATCHES: string[];
  ENTER_BUDGET?: string[];
  WITHDRAW_BUDGET?: string[];
  CREATE_EDIT_AREAS_AND_TEAMS?: string[];
  AREA_LIMIT_SETTINGS?: string[];
  CARD_CREATIOM_AND_EDITING?: string[];
  CANCEL_CARD?: string[];
  IMMEDIATE_BLOCKAGES;
  INACTIVATE_USER?: string[];
  CARD_CREATIOM_AND_EDITING_APPROVER?: string[];
  MAXIMUM_RECHARGE_AMOUNT?: string[];
  BUDGET_ALLOCATION_TO_AREAS?: string[];
  INCREASE_IN_THE_QUOTA_OF_THE_CARD?: string[];
  IMMEDIATE_BLOCKAGES_APPROVER?: string[];
}

export const COMPLEMENT_MODULES = [RoleModules.IRISCARD, RoleModules.IRISPAY];
