import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IrisOtpConstants, OtpViewModel } from '@iris/iris-otp';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { COMPLEMENT_MODULES, Role, RoleAuthorizers, RoleCreate, RoleFilter, RoleModulePermission, RolePermission, RoleType, } from 'src/lib/models/roles.model';
import { BaseService } from '../base/base.service';
import { AddOns } from '../addOns/addOns.types';
import { ComplementPipe } from '../../pipes/complement.pipe';
import { SettingsService } from '../settings/settings.service';
import { Tag } from '@iris/iris-components';
import { RoleModules } from "./role.types";

@Injectable()
export class RoleService extends BaseService {
  private modulesPermissionsCache$: Observable<RoleModulePermission[]>;
  private roleCaches$: any = {};

  constructor(protected http: HttpClient, protected settings: SettingsService) {
    super(http, settings);

    this.complementPipe = new ComplementPipe();
  }

  private complementPipe: ComplementPipe;

  get(filters?: RoleFilter, cached?: boolean): Observable<Role[]> {
    if (!filters) {
      filters = {
        enabled: true,
      };
    }

    if (cached || cached === undefined) {
      const key = btoa(JSON.stringify(filters));
      const cache$ = this.roleCaches$[key];

      if (!cache$) {
        this.roleCaches$[key] = this.get(filters, false).pipe(
          map(roles => roles.sort((a, b) => a.name.localeCompare(b.name))),
          shareReplay(),
        );
      }

      return this.roleCaches$[key];
    }

    return this.http
      .get<Role[]>(this.settings.get.roleApi, {
        params: filters as any,
      })
      .pipe(map(roles => roles.sort((a, b) => a.name.localeCompare(b.name))));
  }

  getById(roleId: string): Observable<Role> {
    return this.http.get<Role>(`${this.settings.get.roleApi}/${roleId}`);
  }

  create(role: RoleCreate, otp: OtpViewModel): Observable<string> {

    return this.http
      .post(this.settings.get.roleApi, role, {
        responseType: 'text',
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      })
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  update(roleId: string, payload: RoleCreate, otp: OtpViewModel) {
    return this.http
      .put(`${this.settings.get.roleApi}/${roleId}`, payload, {
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      })
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  enable(role: Role) {
    return this.http
      .put(`${this.settings.get.roleApi}/${role.id}/enable`, null)
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  disable(role: Role) {
    return this.http
      .put(`${this.settings.get.roleApi}/${role.id}/disable`, null)
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  getDepositAccountsById(roleId: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.settings.get.roleApi}/${roleId}/depositAccounts`,
    );
  }

  getUsersById(roleId: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.settings.get.roleApi}/${roleId}/users`,
    );
  }

  getModulesPermissions(
    roleType: RoleType,
  ): Observable<RoleModulePermission[]> {
    let params = new HttpParams().set('roleType', roleType.toString());
    return this.http.get<RoleModulePermission[]>(
      `${this.settings.get.roleApi}/permissions`,
      {
        params,
      },
    );
  }

  getPermissionsById(roleId: string): Observable<RolePermission[]> {
    return this.http.get<RolePermission[]>(
      `${this.settings.get.roleApi}/${roleId}/permissions`,
    );
  }

  getAuthorizers(roleId?: string): Observable<RoleAuthorizers> {
    let params = new HttpParams().set('roleId', roleId);

    return this.http.get<RoleAuthorizers>(
      `${this.settings.get.roleApi}/permissions/transactions/authorizers`,
      {
        params,
      },
    );
  }

  resetCache(): void {
    this.roleCaches$ = {};
    this.modulesPermissionsCache$ = undefined;
  }

  getTags(permissions: RolePermission[]): Tag[] {
    const tags = [];

    if (permissions.find(p => !COMPLEMENT_MODULES.includes(p.module))) {
      tags.push({
        name: 'Banca',
        color: '#8AFFF3',
      });
    }

    if (permissions.find(p => p.module === RoleModules.IRISPAY)) {
      tags.push({
        name: this.complementPipe.transform(AddOns.IrisPay),
        color: '#8AFFF3',
      });
    }

    return tags as Tag[];
  }
}
