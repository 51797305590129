import { WeekDay } from '@angular/common';
import {
  Operation,
  SchedulePeriodicity,
  TransactionStatus,
  TransferType,
  IrisPayPaymentMethod
} from '../services/transaction/transaction.types';
import { IMapUserName } from '../services/user/user.types';
import { DepositAccountType } from './depositaccount.model';
import { LogChannel } from './log.model';
import {
  Metric
} from "../../app/administration/tags/components/tags-resume-card/components/deposit-accounts/components/tags-resume/tags.resume.types";

export enum MambuTransactionType {
  DEPOSIT = 'DEPOSIT',
  TRANSACTION = 'TRANSACTION',
  INTEREST = 'INTEREST',
  FEE = 'FEE',
}

export enum MambuTransactionStatus {
  PENDING_ACH = 'PENDING_ACH',
  PROCESSED = 'PROCESSED',
  REJECTED = 'REJECTED',
  ROLLED_BACK = 'ROLLED_BACK',
  ROLLED_BACK_FEE = 'ROLLED_BACK_FEE',
}

export enum PendingTransactionType {
  TRANSACTIONS_OWN_ACCOUNTS,
  TRANSACTIONS_ACCOUNTS_SAME_BANK,
  TRANSACTIONS_ACCOUNTS_OTHER_BANK,
}

export enum FrequencyType {
  WEEKLY = 1,
  MONTHLY = 2,
}

export enum CardPaymentChannels {
  CONTACT = "CONTACT",
  CONTACTLESS = "CONTACTLESS",
  CLOUD = "CLOUD",
  MONEYSEND = "MONEYSEND",
  ECOMMERCE = "ECOMMERCE"
}

export interface Transaction {
  originEncodedKey: string;
  details: string;
  reference: string;
  operation: Operation;
  encodedKey: string;
  type: MambuTransactionType;
  amount: number;
  currencyCode: string;
  date: string;
  accountEncodedKey: string;
  feesAmount: number;
  channel?: LogChannel;
  userId?: string;
  tagId?: string;
  destinationAccountHolder?: AccountHolder;
  destinationAccount?: DestinationAccount;
  transactionStatus?: MambuTransactionStatus;
  transactionCost?: number;
  pse?: PseViewModel;
  irisPay?: IrisPayViewModel;
  totalPayment?: number;
  referencePaymentId?: string;
  transactionChannelId?: string;
  cardPayment: CardPaymentViewModel;
}

export interface PseViewModel {
  entityName: string;
  trazabilityCode: string;
  transactionValue: number;
  vatValue: number;
  soliciteDate: string;
  ticketId: string;
  serviceCode: string;
  entityCode: string;
  entityUrl: string;
  paymentDescription: string;
  referenceNumber1: string;
  referenceNumber2: string;
  referenceNumber3: string;
  payerId: string;
  transactionCost: number;
}

export interface IrisPayViewModel {
  customerName: string;
  customerDocumentNumber: string;
  paymentMethod: IrisPayPaymentMethod;
}

export interface Schedule {
  date?: string;
  startDate?: string;
  endDate?: string;
  targetDate?: string;
  weekday?: WeekDay;
  periodicity?: SchedulePeriodicity;
}

export interface AccountHolder {
  name: string;
  documentType: string;
  documentId: string;
  email?: string;
}

export interface DestinationAccount {
  id: string;
  displayId?: string;
  type: DepositAccountType;
  bankId?: string;
  bankNumber?: string;
  accountHolder?: AccountHolder;
  transactionType?: TransferType;
}

export interface PendingTransaction extends IMapUserName {
  id?: number;
  sourceAccountId: string;
  reason: string;
  destinationAccount: DestinationAccount;
  amount: number;
  depositAccountDescription?: string;
  authorizerId?: string;
  authorizerIds: string[];
  createdAt: string;
  processedAt?: string;
  updatedAt?: Date;
  status: TransactionStatus;
  type: Operation;
  schedule: Schedule;
  details?: string;
  batchId?: number;
  tagId?: string;
  transactionCost?: number;
  pse?: PseViewModel;
  irisCard?: null | IrisCardType<IrisCardTypeValues>
}

export interface TransactionSummary {
  inFlow: string;
  truncatedInFlow: string;
  outFlow: string;
  truncatedOutFlow: string;
  currencyCode: string;
}

export interface TransactionRejection {
  ids: number[];
  rejectType: string;
  details: string;
}

export enum TransactionMenu {
  PENDING = 'pending',
  HISTORY = 'history',
  SCHEDULED = 'scheduled',
}

export interface GroupedResults<T> {
  results: T[];
  key: string;
}

export interface ResumePagination {
  totalResults?: number;
  results?: Metric[]
}

export interface ResumeFilterModel {
  uuid: string;
  startAt: string;
  endAt: string;
  page: number;
  totalPerPage: number;
  groupBy: string;
  status?: TransactionStatus[];
}
export interface IrisCardType<T> {
  type: string;
}
export enum IrisCardTypeValues {
  WITHDRAWAL_TRANSACTIONS = 'WITHDRAWAL_TRANSACTIONS',
  DEPOSIT_TRANSACTIONS = 'DEPOSIT_TRANSACTIONS',
}

export interface CardPaymentViewModel {
  area: string;
  countryCode: string;
  transactionChannel: string;
  cardLastFourDigits: string;
  cardId: string;
  currency: string;
  mcc: string;
  type: string;
  merchantName: string;
  status: string;
}
